import './ProductPage.css';
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams, Redirect} from 'react-router-dom';
import request from '../request';
import config from '../config';
import NumberPicker from '../number-picker/NumberPicker';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faShoppingBasket, faTimes, faPaperPlane, faBasketballBall, faRedo, faImages} from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import favouritesHelper from '../favouritesHelper';
import history from '../history';
import ProductAvailaibility from '../product-availability/ProductAvailability';
import basket from '../basket';
import ProductColors from '../product-colors/ProductColors';
import Product360 from '../product-360/Product360';
import ProductPrices from '../product-prices/ProductPrices';
import ProductBasket from '../product-basket/ProductBasket';
import ProductDiscount from '../product-discount/ProductDiscount';
import gtag_evt from '../gtag';
import ProductGallery from '../product-gallery/ProductGallery';
import storage from "../storage";
import Utils from "../Utils";

var clearStorageOnChangeIndex;
function ProductPage(props) {
  const params = useParams();

  const [productId, setProductId] = useState(params.productId)
  const [gtag_init, setGtagInit] = useState(false)
  const [fetchingProduct, setFetchingProduct] = useState(false);
  const [product, setProduct] = useState({
    availability: {},
  });
  const [showToast, setShowToast] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);

  const [imageView, setImageView] = useState("colors");
  const [play360, setPlay360] = useState(false);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  
  const fetchProduct = () => {
    let prods = JSON.parse(localStorage.getItem("basket"));
    request.post(`api/product/${productId ?? params.productId}`, false ).then((response) => {
      // console.log("response is", response)
      if(!response.data.id){
        history.push("/categories");
      }
      const productUpdated = {
        id: response.data.id,
        name: response.data.title,
        name_en: response.data.title_en,
        thumbnail: response.data.thumbnail,
        code: response.data.code,
        miniDescription: response.data.mini_description,
        miniDescription_en: response.data.mini_description_en,
        description: response.data.description,
        description_en: response.data.description_en,
        image: response.data.thumbnail,
        rating: response.data.rating,
        price: response.data.initial_price,
        discountPrice: response.data.price,
        availability: response.data.availability,
        variants: response.data.variants,
        colors: response.data.colors,
        i360: response.data.i360,
        gallery: response.data.gallery ? response.data.gallery : [],
        prices: response.data.prices,
        current_price: null,
        picking_package: response.data.picking_package,
        picking_price: response.data.picking_price,
        picking_limit: response.data.picking_limit,
        passive_discounts: response.data.passive_discounts,
        active_discounts: response.data.active_discounts,
        marketing_message: response.data.marketing_message,
        manufacturer: response.data.manufacturer,
        discount: response.data.discount ? response.data.discount : null,
        campain: response.data.campain ? response.data.campain : null,
      };

      if (!gtag_init) {
        const item_price = productUpdated.prices && productUpdated.prices.length > 0 ? productUpdated.prices[0].price : 0;
        gtag_evt.gtag('event', 'view_item', {
          currency: 'EUR',
          items: [{
            item_id: productUpdated.code,
            item_name: productUpdated.name,
            item_brand: productUpdated.manufacturer,
            item_category: null,
            price: item_price,
            currency: 'EUR'
          }],
          value: item_price
        })

        setGtagInit(true)
      }

      if (storage.me.seller_price_mode && storage.me.seller_price_percentage > 0) {
        productUpdated.prices = productUpdated.prices.map((price) => {
          price.price += (storage.me.seller_price_percentage / 100) * price.price;
          if (price.old_price) {
            price.old_price += (storage.me.seller_price_percentage / 100) * price.old_price;
          }
          return price
        })
      }
      setProduct(productUpdated);
      setFetchingProduct(false);
      
      document.title = `${i18n.get_name(productUpdated)} - ${config.eshop_title}`;
      let lastSeenProducts = localStorage.getItem('last-seen-products');
      if (lastSeenProducts) {
        lastSeenProducts = JSON.parse(lastSeenProducts);
      } else {
        lastSeenProducts = [];
      }

      if (lastSeenProducts.indexOf(params.productId) === -1) {
        lastSeenProducts.push(params.productId);
        if (lastSeenProducts.length > 8) {
          lastSeenProducts.shift();
        } 
      }
      localStorage.setItem('last-seen-products', JSON.stringify(lastSeenProducts));
    });
  }

  useEffect(() => {
    clearStorageOnChangeIndex = storage.onChange((key, data) => {
      if (key === 'me') {
        forceUpdate();
        setFetchingProduct(true);
        fetchProduct(productId);
      }
    })
    const unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      forceUpdate();
    });

    return () => {
      unlistenOnLanguageChange();
      storage.clearOnChange(clearStorageOnChangeIndex);
    };
  }, []);

  useEffect(() => {
    storage.clearOnChange(clearStorageOnChangeIndex);
    clearStorageOnChangeIndex = storage.onChange((key, data) => {
      if (key === 'me') {
        forceUpdate();
        setFetchingProduct(true);
        fetchProduct(productId);
      }
    })
    setProductId(params.prductId);
    setFetchingProduct(true);
    fetchProduct();
  }, [params]);

  useEffect(() => {
    if(imageView === '360'){
      setPlay360(true);
    }else{
      setPlay360(false);
    }
  }, [imageView])

  useEffect(() => {
    setFetchingProduct(true);
    fetchProduct(); 
    
  }, []);

  function onChangeImage(p) {
    if(p.url){
      setProduct({
        ...product,
        thumbnail: p.url,
      });
    }
  }

  function closeToast() {
    setShowToast(false);
  }

  function addToFavourite() {
    favouritesHelper.add(product);
  }

  function onPriceSetChange(e){
    setProduct({
      ...product,
      current_price: e.price
    });
    forceUpdate()
  }

  function onDiscountTrigger(e) {
    let act = product.active_discounts;
    if(e.show){
      act.discount.total_qty = e.total_qty;
      setProduct({
        ...product,
        active_discounts: act
      });
    }
    setShowDiscount(e.show)
  }
  
  function closeDiscount(e) {
    setShowDiscount(false)
  }

  return (
    <div className="product-page">
      <div className={`skeleton ${fetchingProduct === false ? 'hidden' : ''}`}>
        <div className="skeleton-image"></div>
        <div className="skeleton-details"></div>
      </div>
      <div className={`basic-info ${fetchingProduct ? 'hidden' : ''}`}>
        <div className="image">
            <div className="images-controls">
              {
                !fetchingProduct && product.i360 && product.i360.length > 0 &&
                <button onClick={ () => setImageView("360")}  className={`${imageView == "360" && "active"}`}>
                  <FontAwesomeIcon icon={faRedo}/> <br/>
                  360°
                </button>
              }
              {
                !fetchingProduct && product.colors &&
                <button onClick={ () => setImageView("colors")} className={`color-btn ${imageView == "colors" && "active"}`}>
                </button>
              }
              {
                !fetchingProduct && product.gallery && product.gallery.length > 0 &&
                <button onClick={ () => setImageView("gallery")} className={`gallery-btn ${imageView == "gallery" && "active"}`}>
                  <FontAwesomeIcon icon={faImages} />
                </button>
              }
            </div>
          {
            Utils.getBrandImage(product.manufacturer) &&
            <div className='brand-img'>
              <img src={`${config.imagesBaseURL}/${Utils.getBrandImage(product.manufacturer)}`} alt="brand image"/>
            </div>
          }
          
          <div className={`images-view color-image-view ${imageView != "colors" && "hidden"}`}>
            <img src={product.thumbnail} alt={i18n.get_name(product)} />
            <div className="mt-5">
              <ProductColors data={product.colors} onChangeImage={onChangeImage}/>
            </div>
          </div>
          {
            !fetchingProduct && product.i360 && product.i360.length > 0 &&
            <div className={`images-view i360-image-view ${imageView != "360" && "hidden"}`}>
              <div className="mt-5">
                <Product360 data={product.i360} play={play360}/>
              </div>
            </div>
          }
          {
            !fetchingProduct && product.gallery && product.gallery.length > 0 &&
            <div className={`images-view gallery-image-view ${imageView != "gallery" && "hidden"}`}>
              <div className="mt-5">
                <ProductGallery data={product.gallery}/>
              </div>
            </div>
          }
          
          {
            !fetchingProduct && product.prices && (! storage.me.seller_mode || storage.me.seller_price_mode) ?
            <div className="mt-5 prices-wrapper">
              <ProductPrices data={product.prices} price={product.current_price}/>
            </div>
            :
            ''
          }
        </div>
        <div className="details">
          <span className="name">{i18n.get_name(product)}</span>
          
          {/* <div className="price">
            <span className="current-price">{calculatePrice()}&nbsp;€</span>
          </div> */}
          
          {product.availability && <ProductAvailaibility data={product.availability} />}
          
          <div className={`extra-info ${fetchingProduct ? 'hidden' : ''}`}>
            {
              !fetchingProduct && product.description && product.description.length > 0 && <React.Fragment>
                <div className="header" style={{fontWeight: '600'}}>#{product.code}</div>
                <div className="description" dangerouslySetInnerHTML={{ __html: i18n.get_desc(product) }}></div>  
                {
                  product.discount && 
                  <div className='discount-main-banner'>{product.discount.percentage}% {i18n.t("discount_on_item_price")}</div>
                } 
                {
                  product.campain && 
                  <div className='discount-main-banner'>{i18n.get_generic(product.campain, 'caption')}</div>
                } 
              </React.Fragment>
            }
          </div>
          <div className="controls">
            <button
              type="button"
              className="btn btn-lg btn-outline"
              onClick={addToFavourite}
            >
              <FontAwesomeIcon icon={faHeart} />
              <span className="d-none">Favourite</span>
            </button>
          </div>
        </div>
      </div>
      {
        !fetchingProduct && product.marketing_message && (! storage.me.seller_mode || storage.me.seller_price_mode) &&
        <div>
            <div className="discount-message">
              {i18n.get_message(product.marketing_message)}
            </div>
        </div>
        
      }
      {
        !fetchingProduct && product.active_discounts && product.active_discounts.discount && ! storage.me.seller_mode && ! storage.me.seller_price_mode &&
        <div>
          {
            product.active_discounts &&
            <div className="discount-message">
              {i18n.t("by_buying")} {product.active_discounts.discount.qty} {i18n.t("pieces")} {i18n.t("you_earn")} {product.active_discounts.discount.discount_type === '1' ? i18n.t("discount_lower") + ' ' + product.active_discounts.discount.percentage + '% ' + i18n.t("for") : i18n.t("gift")} {i18n.t("same_qty_for_product")} #{product.active_discounts.discount.passive_product.code}
            </div>
          }
          <ProductDiscount discount={product.active_discounts.discount} show={showDiscount} closeDiscount={(e) => closeDiscount(e)} />
        </div>
        
      }
      {
        !fetchingProduct && product.variants ?
        <ProductBasket product={product} onPriceSetChange={ (e) => onPriceSetChange(e)} onDiscountTrigger={ (e) => onDiscountTrigger(e) }/> : ''
      }
      
      <div className={`toast ${showToast ? 'open' : ''}`}>
        <FontAwesomeIcon
          icon={faTimes}
          className="btn-close"
          onClick={closeToast}
        />
        {i18n.t('product_added')}
        &nbsp;
        <Link to="/basket">{i18n.t('to_your_basket')}</Link>
      </div>
    </div>
  );
}

export default ProductPage;