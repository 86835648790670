import { useState, useEffect, } from "react";
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import history from './history';
import Header from './header/Header';
import Footer from './footer/Footer';
import ContactPage from './contact-page/ContactPage';
import HomePage from './home-page/HomePage';
import BlogPage from "./blog-page/BlogPage";
import CategoriesPage from "./categories-page/CategoriesPage";
import PageHeader from "./page-header/PageHeader";
import Basket from './basket/Basket';
import UserMenu from "./user-menu/UserMenu";
import Terms from './terms/Terms';
import ProductPage from './product-page/ProductPage';
import Signin from './signin-page/SigninPage';
import Signup from './signup-page/SignupPage';
import ProductsPage from "./products-page/ProductsPage";
import BasketPage from "./basket-page/BasketPage";
import CheckoutPage from "./checkout-page/CheckoutPage";
import ProductsMiscPage from "./products-misc-page/ProductsMiscPage";
import AccountPage from "./account-page/AccountPage";
import request from "./request";
import storage from "./storage";
import Cookies from 'universal-cookie';
import basket from './basket';
import discountBasket from "./discountBasket";
import RestorePasswordPage from "./restore-password-page/RestorePasswordPage";
import ProductsHomePage from "./products-home-page/ProductsHomePage";
import AboutUs from "./about-us/AboutUs";
import Article from "./article/Article";
import Campain from "./campain/Campain";
import Downloads from "./downloads/Downloads";
import config from "./config";
import Announcement from "./announcement/Announcement";

const onOpenBasketFns = [];
const onCloseBasketFns = [];

const onOpenUserMenuFns = [];
const opCloseUserMenuFns = [];

function App(props) {
  const [user, setUser] = useState({
    authenticated: false,
  });

  const cookies = new Cookies();

  const [has_access, setAccess] = useState(config.enviroment === 'production' || config.enviroment === 'local');

  const [basketOpen, setBasketOpen] = useState(false);

  const [loading, setLoading] = useState(true);

  const [headerType, setHeaderType] = useState(localStorage['header-fixed'] === '1' ? 'sticky' : 'normal');

  function attachListenerOnOpenBasket(fn) {
    onOpenBasketFns.push(fn);
  }

  function attachListenerOnCloseBasket(fn) {
    onCloseBasketFns.push(fn);
  }

  function attachListenerOnOpenUserMenu(fn) {
    onOpenUserMenuFns.push(fn);
  }

  function attachListenerOnCloseUserMenu(fn) {
    opCloseUserMenuFns.push(fn);
  }

  useEffect(() => {
    let c = cookies.get('access');
    if(c === 'password'){
      setAccess(true);
    }

    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.get('user-access-token')) {
      let token = queryParams.get('user-access-token');
      request.get(`api/use-access-token?token=${token}`).then((response) => {
        console.log(response)
      });
    }
  }, []);

  function onOpenBasket() {
    onOpenBasketFns.forEach((fn) => {
      fn();
    });
  }

  function onCloseBasket() {
    onCloseBasketFns.forEach((fn) => {
      fn();
    });
  }

  function onOpenUserMenu() {
    onOpenUserMenuFns.forEach((fn) => {
      fn();
    });
  }

  function onCloseUserMenu() {
    opCloseUserMenuFns.forEach((fn) => {
      fn();
    });
  }

  

  function headerTypeChanged(type) {
    setHeaderType(type);
  }

  useEffect(() => {
    request.get(`api/company`).then((response) => {
      storage.set('company', {
        ...response.data,
        active_announcement: response.data.active_announcement != '0'
      });
      config.eshop_title = response.data.eshop_name ?? 'Present Team';
      window.i18n.refreshTitles();
      setLoading(false);
    });

    request.get(`api/categories-tree`).then((response) => {
      storage.set('categories_tree', response.data);
      //setLoading(false);
    });
    checkSignInStatus()
    //checkSignInStatus();
  }, []);

  

  useEffect(() => {
    if (!loading) {
      (document.querySelector('#loading') || { remove() {} }).remove();
    }
  }, [loading]);

  function onLogout() {
    setUser({
      authenticated: false,
    });
    basket.clear_object()
    discountBasket.clear_object()
    storage.set('me', {})
    history.push('/signin')
  }

  function checkSignInStatus() {
    request.get('api/status', false).then((response) => {
      if (response.data.login) {
        setUser({
          authenticated: true,
        });
        storage.set('me', response.data.data);
        basket.initializeBasket()
        discountBasket.initializeBasket()
      }else{
        setUser({
          authenticated: false,
        });
        storage.me = {};
      }
    });
  }

  function checkUserLoggedIn() {
    request.get('api/status').then((response) => {
      if (response.data.login) {
        return true
      }else{
        return false
      }
    });
  }

  function onLogin() {
    setUser({
      authenticated: true,
    });
    basket.initializeBasket(true)

    checkSignInStatus();
  }

  function testPass(){
    let pass = document.getElementById("test").value;
    if(pass == 'password'){
      setAccess(true);
      cookies.set('access','password', { path: '/' });
    }
  }

  function randomGen() {
    return Math.random()
  }

  return (
    
    <div className={`App ${headerType === 'sticky' ? 'header-fixed' : ''}`} style={{visibility: loading ? 'hidden' : 'visible'}}>
      { has_access ?
      <Router
        history={history}
      >
        <Header
          onOpenBasket={onOpenBasket}
          onCloseBasket={onCloseBasket}
          onOpenUserMenu={onOpenUserMenu}
          onCloseUserMenu={onCloseUserMenu}
          onHeaderTypeChange={headerTypeChanged}
          user={user}
          onLogout={onLogout}
          loading={loading}
        ></Header>
        { ! loading &&
          <Announcement />
        }
        <Switch>
          <Route  exact path="/">
            <HomePage
            key={randomGen()}
            />
          </Route>
          <Route  path="/products/:productId">
            <PageHeader
            key={randomGen()}
              
            />
            <ProductPage
            />
          </Route>
          <Route  path="/restore_password/:token">
            <RestorePasswordPage
            />
          </Route>
          <Route  path="/products">
            <PageHeader
            key={randomGen()}
              page="products"
            />
            <ProductsMiscPage

            />
          </Route>
          {//<ProductsHomePage />
          }
          
          <Route  path="/categories/:categoryId">
            <PageHeader
            key={randomGen()}
              
            />
            <ProductsPage
            />
          </Route>
          
          <Route  path="/categories/:categoryId/:page">
            <PageHeader
            key={randomGen()}
              
            />
            <ProductsPage
            
            />
          </Route>
          <Route  path="/categories/:categoryId/:page/:attrs">
            <PageHeader
            key={randomGen()}
              
            />
            <ProductsPage
            
            />
          </Route>
          <Route  path="/categories">
            <ProductsPage
            />
          </Route>
          <Route  path="/search/products/:query">
            <ProductsPage
            key={randomGen()}
            
            />
          </Route>
          <Route  path="/contact">
            <PageHeader
            key={randomGen()}
              page={'contact'}
            />
            <ContactPage />
          </Route>
          <Route  path="/blog">
            <PageHeader
            key={randomGen()}
              page={'blog'}
            />
            <BlogPage />
          </Route>
          <Route  path="/categories">
            <PageHeader
            key={randomGen()}
              page={'categories'}
            />
            <CategoriesPage />
          </Route>
          <Route  path="/checkout">
            <PageHeader
            key={randomGen()}
              page={'checkout'}
            />
            <CheckoutPage
            />
          </Route>
          <Route  path="/signin">
            <PageHeader
            key={randomGen()}
              page={'signin'}
            />
            <Signin
              onLogin={onLogin}
              checkUserLoggedIn={checkUserLoggedIn}
            />
          </Route>
          <Route  path="/signup">
            <PageHeader
            key={randomGen()}
              page={'signup'}
            />
            <Signup 
              onLogin={onLogin}
            />
          </Route>
          <Route  path="/basket">
            <PageHeader
            key={randomGen()}
              page={'basket'}
            />
            <BasketPage
            />
          </Route>
          <Route  path="/terms/:id">
            <Terms
            key={randomGen()} 
              component={Terms}
            />
          </Route>
          <Route  path="/about-us/:id">
            <AboutUs
            key={randomGen()} 
              component={AboutUs}
            />
          </Route>
          <Route  path="/article/:id">
            <Article
            key={randomGen()} 
              component={Article}
            />
          </Route>
          <Route  path="/campain/:id">
            <Campain
            key={randomGen()} 
              component={Campain}
            />
          </Route>
          <Route  path="/account/:tab">
            <AccountPage
                key={randomGen()} />
          </Route>
          <Route  path="/account">        
            <AccountPage
            key={randomGen()} />
          </Route>
          <Route path="/blog">
            <BlogPage
            key={randomGen()}
            />
          </Route>
          <Route path="/downloads">
            <Downloads
            key={randomGen()}
            />
          </Route>
        </Switch>
        <Footer></Footer>
        <Basket
          attachListenerOnOpen={attachListenerOnOpenBasket}
          attachListenerOnClose={attachListenerOnCloseBasket}
        ></Basket>
        <UserMenu
            attachListenerOnOpen={attachListenerOnOpenUserMenu}
            attachListenerOnClose={attachListenerOnCloseUserMenu}
        ></UserMenu>
      </Router>
      : 
      <div>
        <h3>Forbitten</h3>
        <h4>Are you a tester? Prove yourself</h4>
        <input type="text" id="test"/>
        <button onClick={testPass}>Check!</button>
      </div>
      
      }
    </div>
  );
}

export default App;
